<template>
  <div id="Footer">
    <div class="column">
      <div class="header-c">
        <h2>Let´s talk about your project.</h2>
        <h3>
          <a href="/home/contact-us">Contact us</a>
        </h3>

      </div>
      <div class="menu-f">
        <a href="/">Home</a>
        <a href="/about">About</a>
        <a href="/portafolio">Portfolio</a>
        <a href="/services">Services</a>
        <a href="/magazine">Magazine</a>
        <a href="/historias-de-exito">Historias de éxito</a>
        <a href="/brief">Brief</a>
        <a href="/lite-sites">Lite sites</a>
      </div>
      <div v-for="dato in datosFooter" :key="dato.urlFooter" v-if="landingWeb" class="menu-f  menu-f-row">
        <a :href="dato.urlFooter">{{ dato.urlTitulo }}</a>
      </div>


      <div class="info-f">
        <div class="content-i">
          <p>Gastón Madrid 157-6 Col. San Benito, Hermosillo, Sonora.</p>
        </div>
        <div class="content-i">
          <p><a href="mailto:info@legrafica.mx">info@legrafica.mx</a></p>
          <p>
            <a href="tel:6622754218">(662) 275 4218</a>
          </p>
        </div>
      </div>
      <div class="soc-m-f">
        <a href="https://es-la.facebook.com/legrafica/" target="_blank" rel="noreferrer noopener">
          <img src="../../assets/icon-fb.svg" alt="sm" class="icon" width="8" height="14" />
        </a>
        <a href="https://www.instagram.com/legrafica/?hl=es" target="_blank" rel="noreferrer noopener">
          <img src="../../assets/icon-ig.svg" alt="sm" class="icon" width="8" height="14" />
        </a>
        <a href="https://twitter.com/legrafica" target="_blank" rel="noreferrer noopener">
          <img src="../../assets/icon-tw.svg" alt="sm" class="icon" width="8" height="14" />
        </a>
        <a href="https://mx.linkedin.com/company/legrafica" target="_blank" rel="noreferrer noopener">
          <img src="../../assets/icon-li.svg" alt="sm" class="icon" width="8" height="14" />
        </a>
      </div>
    </div>
    <img src="../../assets/iguana-tatuada.webp"
    srcset="../../assets/iguana-tatuada-1390.webp 1390w,
    ../../assets/iguana-tatuada-1210.webp 1210w,
    ../../assets/iguana-tatuada.webp 990w,
    ../../assets/iguana-tatuada-780.webp 780w,
    ../../assets/iguana-tatuada-400.webp 400w" 
    sizes="51.58vw" 
     alt="iguana" class="iguana-tatuada" />
  </div>
</template>
<script>
export default {
  props: {
    datosFooter: Array,

    landingWeb: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {

      datosFooter: [] 
    }
  }
};
</script>
<style>
#Footer {
  background-color: #b2acfc;
  display: flex;
  position: relative;
  z-index: 9;
}

.iguana-tatuada {
  width: 51.563vw;
  height: auto;
  display: block;
}

.iguana-tumbada {
  display: none;
}

.column:first-child {
  padding: 8.385vw 0 4.427vw 9.688vw;
}

.header-c h2 {
  font-family: "Gramatika-Medium";
  font-size: 5.208vw;
  line-height: 81.3%;
  letter-spacing: -0.015em;
  color: #ffb6ea;
  font-weight: normal;
  margin: 0;
  width: 27vw;
  padding-bottom: 7.135vw;
}

.header-c h3 a {
  font-family: "Gramatika-Medium";
  font-size: 2.344vw;
  line-height: 81.3%;
  letter-spacing: -0.015em;
  text-decoration-line: underline;
  color: #ffffff;
  text-decoration-color: white;
  font-weight: normal;
}

.menu-f {
  width: 36.469vw;
  padding-top: 2.198vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.menu-f a {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 0.729vw;
  line-height: 213.3%;
  color: #ffffff;
  transition: 0.5s;
}

.menu-f a:hover {
  color: #a96ad6;
}

.info-f {
  padding-top: 3.854vw;
  display: flex;
  justify-content: space-between;
  /*width: 38.906vw;*/
}

.column {
  flex-direction: column;
  gap: 1vw;
  padding-top: 3vw;
}

.content-i {
  width: 18.646vw;
  height: 5.365vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-image: url("../../assets/line-pink.svg");
  background-repeat: no-repeat;
  background-size: 2.292vw;
}

.content-i p {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 500;
  font-size: 0.729vw;
  line-height: 213.3%;
  color: #ffffff;
  margin: 0;
}

.content-i p a {
  color: #ffffff;
  transition: 0.5s;
}

.content-i p a:hover {
  color: #a96ad6;
}

.soc-m-f {
  padding-top: 2.917vw;
  width: 7.869vw;
  display: flex;
  justify-content: space-between;
}

.soc-m-f .icon {
  width: auto;
  height: 1.042vw;
}

.menu-f-row{
      width: 35vw;
      grid-template-columns: 1fr !important;
  }
@media (max-width: 768px) {

  .menu-f-row {
        width: 70vw;
    }
  #Footer {
    background-color: #b2acfc;
    display: flex;
    flex-direction: column;
  }

  .column:first-child {
    padding: 16.184vw;
  }

  .column {
    display: flex !important;
    flex-direction: column;
    gap: 1vw;
    padding-top: 3vw;
    flex-wrap: nowrap !important;
    padding-bottom: 5vw;
    height: auto;
  }

  .iguana-tatuada {
    display: none;
  }

  .iguana-tumbada {
    width: 100vw;
    height: auto;
    display: block;
  }

  .header-c h2 {
    font-size: 12.077vw;
    width: 62vw;
    padding-bottom: 12.319vw;
  }

  .header-c h3 {
    margin: 0;
  }

  .header-c h3 a {
    font-size: 2.463054187192118vh;
    line-height: 81.3%;
  }

  .menu-f a {
    font-size: 1.4778325123152707vh;
    line-height: 213.3%;
    margin-bottom: 3vw;
  }

  .menu-f {
    width: 82.7vw;
    height: auto;
    display: grid;
    grid-column: 1fr 1fr;
    padding-bottom: 1vw;
  }

  .info-f {
    padding-top: 0;
    display: flex;
    justify-content: space-between;
    /* width: 38.906vw; */
    flex-direction: column;
  }

  .content-i {
    width: fit-content;
    height: fit-content;
    background-size: 10.292vw;
    padding-top: 5vw;
    padding-bottom: 5vw;
  }

  .content-i p {
    font-size: 1.4778325123152707vh;
  }

  .soc-m-f {
    padding-top: 13.285vw;
    width: 43.720vw;
    display: flex;
    justify-content: space-between;
  }

  .soc-m-f .icon {
    height: 3.998vw;
  }
}
</style>