<template  >
  <div>
    <div class="wapp-icon">
      <a
        :href="link"
       target="_blank" rel="noopener noreferrer">
        <div class="titlewapp-cont">
          <p>Platiquemos de tu proyecto</p>
        </div>
        <div class="logo" /></a>

        <!--src="../../assets/logo-wapp.svg"-->
    </div>
  </div>
</template>
<script>
export default {
  props: ['link']
};
</script>
<style scoped>
.wapp-icon {
  position: fixed;
  z-index: 543;
  right: 8vh;
  bottom: 5.6640625vh;
  transition: 0.5s;
}
.wapp-icon a {
  display: flex;
  align-items: center;
}
.titlewapp-cont {
  overflow: hidden;
  width: 0;
  height: 3.171875vh;
  transition: .5s;
}
.wapp-icon:hover .titlewapp-cont {
  /*width: 45vh;*/
  width: 30vh;
}
.titlewapp-cont p {
  margin: 0;
  font-family: "Gramatika-Bold";
  font-style: normal;
  font-weight: 500;
  font-size: 2.171875vh;
  line-height: 143.3%;
  animation-name: loulou2;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  -webkit-animation-name: loulou2;
  -webkit-animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: loulou2;
  -moz-animation-duration: 5s;
  -moz-animation-iteration-count: infinite;
}
.wapp-icon .logo {
  width: 7.2265625vh;
  height: 7.2265625vh;
  transition: 0.5s;
  background-image: url("../../assets/logo-wapp.svg");
  background-repeat: no-repeat;
  background-size: 7.2265625vh 7.2265625vh;
  animation-name: loulou2;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  -webkit-animation-name: loulou;
  -webkit-animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: loulou;
  -moz-animation-duration: 5s;
  -moz-animation-iteration-count: infinite;
}
/*.wapp-icon:hover .logo {
  width: 8.6265625vh;
  height: 8.6265625vh;
}*/

@keyframes loulou {
  0% {
  width: 7.2265625vh;
  height: 7.2265625vh;
  background-size: 7.2265625vh 7.2265625vh;
    background-image: url("../../assets/logo-wapp.svg");
  }
  20% {
  width: 7.2265625vh;
  height: 7.2265625vh;
  background-size: 7.2265625vh 7.2265625vh;
    background-image: url("../../assets/logo-wapp-1.svg");
  }
  40% {
  width: 7.2265625vh;
  height: 7.2265625vh;
  background-size: 7.2265625vh 7.2265625vh;
    background-image: url("../../assets/logo-wapp-2.svg");
  }
  60% {
  width: 7.2265625vh;
  height: 7.2265625vh;
  background-size: 7.2265625vh 7.2265625vh;
    background-image: url("../../assets/logo-wapp-3.svg");
  }
  80% {
  width: 7.2265625vh;
  height: 7.2265625vh;
  background-size: 7.2265625vh 7.2265625vh;
    background-image: url("../../assets/logo-wapp-4.svg");
  }
  100% {
  width: 7.2265625vh;
  height: 7.2265625vh;
  background-size: 7.2265625vh 7.2265625vh;
    background-image: url("../../assets/logo-wapp.svg");
  }
}
/*
@-webkit-keyframes loulouW {
  0% {
  width: 7.2265625vh;
  height: 7.2265625vh;
  background-size: 7.2265625vh 7.2265625vh;
    background-image: url("../../assets/logo-wapp.svg");
  }
  20% {
  width: 7.2265625vh;
  height: 7.2265625vh;
  background-size: 7.2265625vh 7.2265625vh;
    background-image: url("../../assets/logo-wapp-1.svg");
  }
  40% {
  width: 7.2265625vh;
  height: 7.2265625vh;
  background-size: 7.2265625vh 7.2265625vh;
    background-image: url("../../assets/logo-wapp-2.svg");
  }
  60% {
  width: 7.2265625vh;
  height: 7.2265625vh;
  background-size: 7.2265625vh 7.2265625vh;
    background-image: url("../../assets/logo-wapp-3.svg");
  }
  80% {
  width: 7.2265625vh;
  height: 7.2265625vh;
  background-size: 7.2265625vh 7.2265625vh;
    background-image: url("../../assets/logo-wapp-4.svg");
  }
  100% {
  width: 7.2265625vh;
  height: 7.2265625vh;
  background-size: 7.2265625vh 7.2265625vh;
    background-image: url("../../assets/logo-wapp.svg");
  }
}
*/
@keyframes loulou2 {
  0% {
    color: #6CFFF2;
  }
  20% {
    color: #FF13D9;
  }
  40% {
    color: #ffffff;
  }
  60% {
    color: #8BDCFF;
  }
  80% {
    color: #8723AA;
  }
  100% {
    color: #6CFFF2;
  }
}
/*
@-webkit-keyframes loulou2W {
  0% {
    color: #6CFFF2;
  }
  20% {
    color: #FF13D9;
  }
  40% {
    color: #ffffff;
  }
  60% {
    color: #8BDCFF;
  }
  80% {
    color: #8723AA;
  }
  100% {
    color: #6CFFF2;
  }
}*/
</style>